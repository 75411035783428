import React, { Fragment } from "react";

type ConditionalWrapperProps = {
	wrapper: (children: React.ReactNode) => React.ReactNode;
	condition: boolean;
	children: React.ReactNode;
};

export const ConditionalWrapper = ({
	wrapper,
	condition,
	children,
}: ConditionalWrapperProps) => {
	if (condition) {
		return <Fragment>{wrapper(children)}</Fragment>;
	}

	return <Fragment>{children}</Fragment>;
};

export const callIfFunction = <TArgs extends any[], TValue>(
	a: TValue | ((...args: TArgs) => TValue),
	...args: TArgs
): TValue => {
	if (a instanceof Function) {
		return a(...args);
	}

	return a;
};

type RowWrapperProps<E extends React.ElementType = React.ElementType> = {
	linkRowTo?: string | ((row: any) => string | null);
	children: React.ReactNode;
	row?: any;
	as?: E;
};

export const RowWrapper = ({
	children,
	linkRowTo,
	row,
	as,
	...props
}: RowWrapperProps) => {
	const Component = as || "a";
	const href = linkRowTo && callIfFunction(linkRowTo!, row);
	if (href) {
		return (
			<Component
				href={href}
				className="block cursor-pointer hover:bg-neutral-50 transition-colors"
				{...props}
			>
				{children}
			</Component>
		);
	}
	return <Fragment>{children}</Fragment>;
};
