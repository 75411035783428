"use client";

import React, { useState } from "react";
import clsx from "clsx";
import {
	ColumnDef,
	ColumnOrderState,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	Row,
	RowData,
	Updater,
	useReactTable,
	VisibilityState,
} from "@tanstack/react-table";
import { AnimatePresence, motion } from "framer-motion";
import { useLocalStorage } from "usehooks-ts";

import { Button, ButtonVariant } from "../click/Button";
import { Skeleton } from "../progress/Skeleton";
import { Table } from "../Table";
import { RowWrapper } from "./utils";

// Re-export createColumnHelper utility
export { createColumnHelper } from "@tanstack/react-table";

export type PageInfo = {
	hasNextPage: boolean;
	totalCount: number;
	endCursor: string;
	startCursor: string;
};

export enum DataTableSortDirection {
	Asc = "ASC",
	Desc = "DESC",
}

export type DataTableSortState<TSortField extends string = string> = {
	field: TSortField;
	direction: DataTableSortDirection;
};

type DataTableLocalStorage = {
	visibility?: VisibilityState;
	order?: ColumnOrderState;
};

function useDataTableLocalStorage(key: string) {
	const [settings, setSettings] = useLocalStorage<DataTableLocalStorage>(
		`table-settings-${key}`,
		{},
	);

	return [settings, setSettings] as const;
}

export function useDataTableInMemorySort<T extends Record<string, any>>(
	data: T[],
	// @ts-ignore
	sort?: DataTableSortState<keyof T>,
	customSortFns?: { field: string; sortFn: (a: T, b: T) => number }[],
) {
	const sortedData = React.useMemo(() => {
		if (!sort) {
			return data;
		}

		const { field, direction } = sort;

		// find sort function for the field
		const customSortFn = customSortFns?.find(
			(sortFn) => sortFn.field === field,
		)?.sortFn;

		// `field` might be a path to a nested value, e.g. "account.firstName"
		const getFieldValue = (obj: Record<string, any>): any =>
			field
				// @ts-ignore
				.replace("_", ".")
				.split(".")
				// @ts-ignore
				.reduce((value, key) => value?.[key] ?? null, obj);

		const sorted = [...data].sort((a, b) => {
			if (customSortFn) {
				return (
					customSortFn(a, b) *
					(direction === DataTableSortDirection.Asc ? 1 : -1)
				);
			}

			const aValue = getFieldValue(a);
			const bValue = getFieldValue(b);

			if (aValue === bValue) {
				return 0;
			}

			if (direction === DataTableSortDirection.Asc) {
				return aValue < bValue ? -1 : 1;
			}

			return aValue > bValue ? -1 : 1;
		});

		return sorted;
	}, [data, sort, customSortFns]);

	return sortedData;
}

export function useDataTableColumnVisibility<TData extends RowData>(
	columns: ColumnDef<TData, any>[],
	storageKey: string,
) {
	const [storedSettings, setStoredSettings] =
		useDataTableLocalStorage(storageKey);

	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
		storedSettings.visibility ??
			columns.reduce((a, c) => (c.id ? { ...a, [c.id]: true } : a), {}),
	);

	const onColumnVisibilityChange = (update: Updater<VisibilityState>) => {
		setColumnVisibility((old) => {
			if (typeof update === "function") {
				setStoredSettings({ ...storedSettings, visibility: update(old) });
				return update(old);
			}

			setStoredSettings({ ...storedSettings, visibility: update });
			return update;
		});
	};

	return {
		columnVisibility,
		onColumnVisibilityChange,
	};
}

export function useDataTableColumnOrder<TData extends RowData>(
	columns: ColumnDef<TData, any>[],
	storageKey: string,
) {
	const [storedSettings, setStoredSettings] =
		useDataTableLocalStorage(storageKey);

	const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
		storedSettings.order ??
			columns.reduce(
				(a, c) => (c.id ? [...a, c.id] : a),
				[] as ColumnOrderState,
			),
	);

	const onColumnOrderChange = (update: Updater<ColumnOrderState>) => {
		setColumnOrder((old) => {
			if (typeof update === "function") {
				setStoredSettings({ ...storedSettings, order: update(old) });
				return update(old);
			}

			setStoredSettings({ ...storedSettings, order: update });
			return update;
		});
	};

	return {
		columnOrder,
		onColumnOrderChange,
	};
}

export type DataTableProps<
	TData extends { id: string },
	TSortField extends string = string,
> = {
	data: TData[];
	columns: ColumnDef<TData, any>[];

	sort?: DataTableSortState<TSortField>;
	onSortChange?: (value: DataTableSortState<TSortField>) => void;

	isLoading?: boolean;
	onLoadMore?: () => void;
	isLoadingMore?: boolean;
	pageInfo?: PageInfo;
	loadingRowCount?: number;

	onRowClick?: (row: TData) => void;

	rowHeight?: "sm" | "md" | "lg";
	rowClassName?: string | ((row: TData) => string);
	hasBorder?: boolean;
	hideFooter?: boolean;
	"data-testid"?: string;
	emptyState?: React.ReactNode;

	getRowCanExpand?: (row: Row<TData>) => boolean;
	renderSubComponent?: (props: { row: Row<TData> }) => React.ReactNode;
	expandRowOnClick?: boolean;
	linkRowTo?: string | ((row: TData) => string | null);
	wrapRowWith?: React.ElementType;

	columnVisibility?: VisibilityState;
	onColumnVisibilityChange?: (
		columnVisibility: Updater<VisibilityState>,
	) => void;

	columnOrder?: ColumnOrderState;
	onColumnOrderChange?: (columnOrder: Updater<ColumnOrderState>) => void;
};

// TODO: I can't figure out how to get declaration merging working across apps
// that are using this package. So for now, we'll just re-export the ColumnMeta
// type.
export interface ColumnMeta<
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
	TData extends RowData,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
	TValue,
	TSortField extends string,
> {
	/**
	 * Specifies the alignment of the cell in the column.
	 * @default "left"
	 */
	align?: "left" | "center" | "right";
	/**
	 * Sets the field to sort by when sorting this column. If not specified, the column will not be sortable.
	 */
	sortField?: TSortField;
	/**
	 * Makes the column sortable, using the column ID as the sort field.
	 * @default false
	 */
	sortable?: boolean;
	/**
	 * Truncates the text in cells in this column.
	 * @default false
	 */
	truncate?: boolean;
}

const MotionTableRow = motion(Table.Row);

export function DataTable<
	TData extends { id: string },
	TSortField extends string = string,
>({
	data,
	columns,
	sort,
	onSortChange,
	isLoading = false,
	onLoadMore,
	isLoadingMore = false,
	pageInfo,
	loadingRowCount = 20,
	onRowClick,
	rowHeight = "sm",
	rowClassName = "",
	hasBorder = false,
	hideFooter = false,
	"data-testid": testId,
	emptyState = null,
	getRowCanExpand,
	renderSubComponent = () => null,
	expandRowOnClick = false,
	wrapRowWith,
	linkRowTo,
	columnVisibility,
	onColumnVisibilityChange,
	columnOrder,
	onColumnOrderChange,
}: DataTableProps<TData, TSortField>) {
	const table = useReactTable({
		columns,
		data,
		getRowCanExpand,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		state: {
			columnVisibility,
			columnOrder,
		},
		onColumnVisibilityChange,
		onColumnOrderChange,
	});

	const isLoadingInitial = isLoading && !isLoadingMore;

	const makeTestId = (id: string) => (testId ? `${testId}-${id}` : null);

	const rowModel = table.getRowModel();
	const headerGroups = table.getHeaderGroups();
	const footerGroups = table.getFooterGroups();

	// Only display the footer if footer cells have been defined, and the hideFooter prop is not true
	const shouldShowFooter =
		footerGroups.some(({ headers }) =>
			headers.some((header) => header.column.columnDef.footer !== undefined),
		) && !hideFooter;

	return (
		<div className="w-full">
			<div
				className={clsx(
					hasBorder &&
						"border-muted rounded-md overflow-hidden bg-white border",
				)}
			>
				<Table data-testid={testId}>
					{headerGroups.map((headerGroup) => (
						<Table.Header
							data-testid={makeTestId("header")}
							key={headerGroup.id}
						>
							{headerGroup.headers.map((header) => {
								const {
									align = "left",
									sortField = null,
									sortable = false,
								} = (header.column.columnDef.meta as ColumnMeta<
									TData,
									any,
									TSortField
								>) ?? {};

								const columnWidth = header.column.getSize();

								const isSortable = sortField !== null || sortable;
								const isSorted =
									isSortable &&
									(sort?.field === sortField || sort?.field === header.id);

								const getNextSortDirection = () => {
									if (!isSortable || !onSortChange || !sort) {
										return null;
									}

									if (!isSorted) {
										return DataTableSortDirection.Asc;
									}

									return sort.direction === DataTableSortDirection.Asc
										? DataTableSortDirection.Desc
										: DataTableSortDirection.Asc;
								};

								const handleSort = () => {
									if (!isSortable || !onSortChange) {
										return;
									}

									const sortDirection = getNextSortDirection()!;

									onSortChange({
										field: sortField || (header.id as TSortField),
										direction: sortDirection,
									});
								};

								const headerContents = header.isPlaceholder
									? null
									: flexRender(
											header.column.columnDef.header,
											header.getContext(),
									  );

								return (
									<Table.Column
										key={header.id}
										data-testid={makeTestId(`header-${header.id}`)}
										style={{
											// Weird workaround to make column widths work as expected -
											// the default column width in react-table is 150, be we
											// want it to be unset if it's not set, so that other
											// columns can still specify fixed widths.
											//
											// Note that this means that we cannot use 150 as a column
											// size, it will end up with an auto width instead.
											//
											// See https://github.com/TanStack/table/discussions/3192#discussioncomment-3873093
											width: columnWidth !== 150 ? columnWidth : undefined,
										}}
										className={clsx(
											"overflow-hidden",
											align === "left" && "text-left",
											align === "center" && "text-center",
											align === "right" && "text-right",
										)}
										sortable={isSortable}
										sorted={isSorted}
										sortDirection={sort?.direction}
										onClick={handleSort}
									>
										{headerContents}
									</Table.Column>
								);
							})}
						</Table.Header>
					))}
					<Table.Body>
						<AnimatePresence exitBeforeEnter>
							{isLoadingInitial ? null : (
								<>
									{rowModel.rows.length === 0 ? (
										<div className="flex min-h-[200px] items-center justify-center">
											{emptyState}
										</div>
									) : (
										rowModel.rows.map((row) => (
											<>
												<RowWrapper
													as={wrapRowWith}
													linkRowTo={linkRowTo}
													row={row.original}
												>
													<MotionTableRow
														key={row.id}
														initial={{ opacity: 0 }}
														animate={{ opacity: 1 }}
														exit={{ opacity: 0 }}
														transition={{ duration: 0.2 }}
														data-testid={makeTestId(`row-${row.original.id}`)}
														onClick={() => {
															onRowClick?.(row.original);
															if (expandRowOnClick) {
																row.toggleExpanded();
															}
														}}
														interactive={!!onRowClick || expandRowOnClick}
														height={rowHeight}
														className={
															typeof rowClassName === "function"
																? rowClassName(row.original)
																: rowClassName
														}
													>
														<>
															{row.getVisibleCells().map((cell) => {
																const { align = "left", truncate = false } =
																	(cell.column.columnDef.meta as ColumnMeta<
																		TData,
																		any,
																		TSortField
																	>) ?? {};
																const columnWidth = cell.column.getSize();

																return (
																	<Table.Cell
																		key={cell.id}
																		data-testid={makeTestId(
																			`cell-${cell.column.id}`,
																		)}
																		style={{
																			width:
																				columnWidth !== 150
																					? columnWidth
																					: undefined,
																		}}
																		className={clsx(
																			"overflow-hidden",
																			truncate && "truncate",
																			align === "left" && "text-left",
																			align === "center" && "text-center",
																			align === "right" && "text-right",
																		)}
																	>
																		{flexRender(
																			cell.column.columnDef.cell,
																			cell.getContext(),
																		)}
																	</Table.Cell>
																);
															})}
														</>
													</MotionTableRow>
												</RowWrapper>
												{row.getIsExpanded() && (
													<tr className="flex">
														<td
															className="grow"
															colSpan={row.getVisibleCells().length}
														>
															{renderSubComponent({ row })}
														</td>
													</tr>
												)}
											</>
										))
									)}
								</>
							)}
							{isLoading ? (
								<>
									{Array.from({ length: loadingRowCount }).map((_, index) => (
										<MotionTableRow
											// eslint-disable-next-line react/no-array-index-key
											key={`loading-${index}`}
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											exit={{ opacity: 0 }}
											transition={{ duration: 0.3 }}
											height={rowHeight}
										>
											{table.getAllColumns().map((column) => {
												const columnWidth = column.getSize();

												return (
													<Table.Cell
														key={column.id}
														style={{
															width:
																columnWidth !== 150 ? columnWidth : undefined,
														}}
													>
														<Skeleton className="my-[5px] flex h-[13px] items-center rounded-md" />
													</Table.Cell>
												);
											})}
										</MotionTableRow>
									))}
								</>
							) : null}
						</AnimatePresence>
					</Table.Body>
					{shouldShowFooter && (
						<Table.Footer data-testid={makeTestId("footer")}>
							{footerGroups.map((footerGroup) => (
								<Table.Row key={footerGroup.id}>
									{footerGroup.headers.map((header) => {
										const size = header.getSize();
										const { align = "left", truncate = false } =
											(header.column.columnDef.meta as ColumnMeta<
												TData,
												any,
												TSortField
											>) ?? {};

										return (
											<Table.Cell
												key={header.id}
												style={{
													width: size !== 150 ? size : undefined,
												}}
												className={clsx(
													truncate && "truncate",
													align === "left" && "text-left",
													align === "center" && "text-center",
													align === "right" && "text-right",
												)}
											>
												{header.isPlaceholder
													? null
													: flexRender(
															header.column.columnDef.footer,
															header.getContext(),
													  )}
											</Table.Cell>
										);
									})}
								</Table.Row>
							))}
						</Table.Footer>
					)}
				</Table>
			</div>

			{!isLoadingInitial && (pageInfo?.hasNextPage ?? false) && (
				<div className="flex items-center justify-center py-6">
					<Button
						variant={ButtonVariant.OutlinePrimary}
						onClick={onLoadMore}
						isLoading={isLoadingMore}
					>
						Show more
					</Button>
				</div>
			)}
		</div>
	);
}
